import { deleteAccessToken, storeAccessToken } from '../../../utility/auth';
import * as actionTypes from './actionTypes';
import { SetUserAuthenticatedActionType, SetUserNotAuthenticatedActionType } from './types';

export const setUserAuthenticated = (token: string, expiresIn: number): SetUserAuthenticatedActionType => {
  deleteAccessToken();
  storeAccessToken(token, expiresIn);

  return {
    type: actionTypes.SET_USER_AUTHENTICATED,
    payload: {
      token,
    },
  };
};

export const setUserNotAuthenticated = (): SetUserNotAuthenticatedActionType => {
  deleteAccessToken();

  return {
    type: actionTypes.SET_USER_NOT_AUTHENTICATED,
  };
};
