import produce from 'immer';
import { combineReducers } from 'redux';
import { assignSuccessState, getFailureState, getLoadingState, getSuccessState } from 'utility/redux';
import * as actionTypes from './actionTypes';
import { CLEAR_SELECTED_COMPANY } from '../companies/actionTypes';
import {
  CreateActivityActionTypes,
  CreateActivityTranslationActionType,
  CreateEntityCategoryActionType,
  CreateEntityCategoryTranslationActionType,
  DivisionsActionType,
  DivisionsStateType,
  DurationOptionsActionType,
  DurationOptionsStateType,
  GetActivitiesActionTypes,
  GetActivitiesStateType,
  RatingQuestionActionType,
  RatingQuestionTranslationActionType,
  SetSelectedActivityType,
  SetSelectedRatingQuestionType,
  UpdateActivityActionTypes,
  UpdateActivityTranslationActionType,
  UpdateEntityCategoryActionType,
  UpdateEntityCategoryTranslationActionType,
  UpdateRatingQuestionActionType,
  UpdateRatingQuestionTranslationActionType,
} from './types';
import { ClearSelectedCompanyType } from 'redux/companies/types';

const selectedActivityId = (state = null, action: SetSelectedActivityType | ClearSelectedCompanyType) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_ACTIVITY_ID: {
      return action.payload.activityId;
    }
    case CLEAR_SELECTED_COMPANY: {
      return null;
    }
    default:
      return state;
  }
};

const activitiesState: GetActivitiesStateType = {
  loading: false,
  hasError: false,
  error: '',
  list: [],
};

const activities = (
  state: GetActivitiesStateType = activitiesState,
  action:
    | GetActivitiesActionTypes
    | CreateActivityActionTypes
    | UpdateActivityActionTypes
    | CreateActivityTranslationActionType
    | UpdateActivityTranslationActionType
    | RatingQuestionActionType
    | UpdateRatingQuestionActionType
    | RatingQuestionTranslationActionType
    | UpdateRatingQuestionTranslationActionType
    | CreateEntityCategoryActionType
    | UpdateEntityCategoryActionType
    | CreateEntityCategoryTranslationActionType
    | UpdateEntityCategoryTranslationActionType,
) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVITIES_LOADING:
    case actionTypes.CREATE_ACTIVITY_LOADING:
    case actionTypes.UPDATE_ACTIVITY_LOADING:
    case actionTypes.CREATE_ACTIVITY_TRANSLATION_LOADING:
    case actionTypes.UPDATE_ACTIVITY_TRANSLATION_LOADING:
    case actionTypes.CREATE_RATING_QUESTION_LOADING:
    case actionTypes.UPDATE_RATING_QUESTION_LOADING:
    case actionTypes.CREATE_RATING_QUESTION_TRANSLATION_LOADING:
    case actionTypes.UPDATE_RATING_QUESTION_TRANSLATION_LOADING:
    case actionTypes.CREATE_ENTITY_CATEGORY_LOADING:
    case actionTypes.UPDATE_ENTITY_CATEGORY_LOADING:
    case actionTypes.CREATE_ENTITY_CATEGORY_TRANSLATION_LOADING:
    case actionTypes.UPDATE_ENTITY_CATEGORY_TRANSLATION_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.GET_ACTIVITIES_SUCCESS: {
      return produce(state, (draft) => {
        draft.list = action.payload.activities;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.CREATE_ACTIVITY_SUCCESS:
      return produce(state, (draft) => {
        draft.list.push(action.payload.activity);

        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.UPDATE_ACTIVITY_SUCCESS:
      return produce(state, (draft) => {
        const { activity, activityIndex } = action.payload;

        draft.list[activityIndex] = activity;
        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.CREATE_ACTIVITY_TRANSLATION_SUCCESS: {
      return produce(state, (draft) => {
        const { activityIndex, activityTranslation } = action.payload;
        draft.list[activityIndex].activityTranslations.push(activityTranslation);

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.UPDATE_ACTIVITY_TRANSLATION_SUCCESS:
      return produce(state, (draft) => {
        const { activityIndex, activityTranslationIndex, activityTranslation } = action.payload;

        draft.list[activityIndex].activityTranslations[activityTranslationIndex] = activityTranslation;
        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.CREATE_RATING_QUESTION_SUCCESS: {
      const { activityIndex, ratingQuestion } = action.payload;

      return produce(state, (draft) => {
        draft.list[activityIndex].activityRatingQuestions.push(ratingQuestion);

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.UPDATE_RATING_QUESTION_SUCCESS: {
      const { activityIndex, ratingQuestionIndex, ratingQuestion } = action.payload;

      return produce(state, (draft) => {
        draft.list[activityIndex].activityRatingQuestions[ratingQuestionIndex] = ratingQuestion;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.CREATE_RATING_QUESTION_TRANSLATION_SUCCESS: {
      const { activityIndex, ratingQuestionIndex, ratingQuestionTranslation } = action.payload;

      return produce(state, (draft) => {
        draft.list[activityIndex].activityRatingQuestions[ratingQuestionIndex].activityRatingQuestionTranslations.push(
          ratingQuestionTranslation,
        );

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.UPDATE_RATING_QUESTION_TRANSLATION_SUCCESS: {
      const { activityIndex, ratingQuestionIndex, ratingQuestionTranslationIndex, ratingQuestionTranslation } =
        action.payload;

      return produce(state, (draft) => {
        draft.list[activityIndex].activityRatingQuestions[ratingQuestionIndex].activityRatingQuestionTranslations[
          ratingQuestionTranslationIndex
        ] = ratingQuestionTranslation;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }

    case actionTypes.CREATE_ENTITY_CATEGORY_SUCCESS: {
      const { activityIndex, entityCategory } = action.payload;

      return produce(state, (draft) => {
        draft.list[activityIndex].entityCategories.push(entityCategory);

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.UPDATE_ENTITY_CATEGORY_SUCCESS: {
      const { activityIndex, entityCategoryIndex, entityCategory } = action.payload;

      return produce(state, (draft) => {
        draft.list[activityIndex].entityCategories[entityCategoryIndex] = entityCategory;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.CREATE_ENTITY_CATEGORY_TRANSLATION_SUCCESS: {
      const { activityIndex, entityCategoryIndex, entityCategoryTranslation } = action.payload;

      return produce(state, (draft) => {
        draft.list[activityIndex].entityCategories[entityCategoryIndex].entityCategoryTranslations.push(
          entityCategoryTranslation,
        );

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.UPDATE_ENTITY_CATEGORY_TRANSLATION_SUCCESS: {
      const { activityIndex, entityCategoryIndex, entityCategoryTranslationIndex, entityCategoryTranslation } =
        action.payload;

      return produce(state, (draft) => {
        draft.list[activityIndex].entityCategories[entityCategoryIndex].entityCategoryTranslations[
          entityCategoryTranslationIndex
        ] = entityCategoryTranslation;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    }
    case actionTypes.GET_ACTIVITIES_FAILURE:
    case actionTypes.CREATE_ACTIVITY_FAILURE:
    case actionTypes.UPDATE_ACTIVITY_FAILURE:
    case actionTypes.CREATE_ACTIVITY_TRANSLATION_FAILURE:
    case actionTypes.UPDATE_ACTIVITY_TRANSLATION_FAILURE:
    case actionTypes.CREATE_RATING_QUESTION_FAILURE:
    case actionTypes.UPDATE_RATING_QUESTION_FAILURE:
    case actionTypes.CREATE_RATING_QUESTION_TRANSLATION_FAILURE:
    case actionTypes.UPDATE_RATING_QUESTION_TRANSLATION_FAILURE:
    case actionTypes.CREATE_ENTITY_CATEGORY_FAILURE:
    case actionTypes.UPDATE_ENTITY_CATEGORY_FAILURE:
    case actionTypes.CREATE_ENTITY_CATEGORY_TRANSLATION_FAILURE:
    case actionTypes.UPDATE_ENTITY_CATEGORY_TRANSLATION_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    case actionTypes.GET_ACTIVITIES_EXISTING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const selectedRatingQuestionId = (state = null, action: SetSelectedRatingQuestionType) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_RATING_QUESTION: {
      return action.payload.ratingQuestionId;
    }
    default:
      return state;
  }
};

const durationOptionsDefaultState: DurationOptionsStateType = {
  loading: false,
  hasError: false,
  error: '',
  byId: {},
};

const durationOptions = (
  state = durationOptionsDefaultState,
  action: DurationOptionsActionType,
): DurationOptionsStateType => {
  switch (action.type) {
    case actionTypes.DURATION_OPTIONS_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.DURATION_OPTIONS_SUCCESS: {
      const { activityId } = action.payload;

      return {
        ...state,
        byId: {
          ...state.byId,
          [activityId]: action.payload.data,
        },
        ...getSuccessState(),
      };
    }
    case actionTypes.DURATION_OPTIONS_FAILURE: {
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    }
    default: {
      return state;
    }
  }
};

/************************* Divisions *************************/
const divisionState: DivisionsStateType = {
  loading: false,
  hasError: false,
  error: '',
  activities: {},
};

const divisions = (state = divisionState, action: DivisionsActionType) => {
  switch (action.type) {
    case actionTypes.DIVISIONS_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.DIVISIONS_SUCCESS:
      return {
        ...state,
        activities: action.payload.data,
        ...getSuccessState(),
      };
    case actionTypes.DIVISIONS_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

export default combineReducers({
  activities,
  selectedActivityId,
  selectedRatingQuestionId,
  durationOptions,
  divisions,
});
