import moment from 'moment';

// expiresIn is in seconds
const storeAccessToken = (accessToken: string, expiresIn: number = 0) => {
  deleteAccessToken();
  const expirationDate = moment().add(expiresIn, 'seconds').toDate();

  document.cookie = `access_token=${accessToken};expires=${expirationDate.toUTCString()};path=/`;
};

const getAccessToken = (): string => {
  return document.cookie.match('(^|;)\\s*access_token\\s*=\\s*([^;]+)')?.pop() || '';
};

// TODO: check if it is better to just add document.cookie.indexOf('access_token')
const accessTokenExists = (): boolean => {
  const accessTokenCookieValue = getAccessToken();

  return accessTokenCookieValue !== null && accessTokenCookieValue !== undefined && accessTokenCookieValue !== '';
};

const deleteAccessToken = () => {
  document.cookie = 'access_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};

export { storeAccessToken, getAccessToken, accessTokenExists, deleteAccessToken };
