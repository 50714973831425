import { EntityCategoriesActionType, EntityCategoriesStateType } from './types';
import * as actionTypes from './actionTypes';
import { getLoadingState, getSuccessState, getFailureState } from 'utility/redux';
import { combineReducers } from 'redux';

const entityCategoriesDefaultState: EntityCategoriesStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const entityCategories = (
  state = entityCategoriesDefaultState,
  action: EntityCategoriesActionType,
): EntityCategoriesStateType => {
  switch (action.type) {
    case actionTypes.ENTITY_CATEGORIES_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.ENTITY_CATEGORIES_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        ...getSuccessState(),
      };
    case actionTypes.ENTITY_CATEGORIES_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

export default combineReducers({
  entityCategories,
});
