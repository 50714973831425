import produce from 'immer';
import { combineReducers } from 'redux';
import { BookingType } from 'types/api/bookings';
import { getFailureState, getLoadingState, getSuccessState } from 'utility/redux';
import * as actionTypes from './actionTypes';
import {
  BookingsActionType,
  BookingsAvailabilityActionType,
  BookingsAvailabilityClearActionType,
  BookingsAvailabilityStateType,
  BookingsForDayActionType,
  BookingsForDayStateType,
  BookingsHistoryActionType,
  BookingsHistoryPaginatedStateType,
  BookingsStateType,
} from './types';

const bookingsDefaultState: BookingsStateType = {
  loading: false,
  hasError: false,
  error: '',
  list: [],
};

const all = (state = bookingsDefaultState, action: BookingsActionType): BookingsStateType => {
  switch (action.type) {
    case actionTypes.BOOKINGS_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.BOOKINGS_SUCCESS:
      return {
        ...getSuccessState(),
        list: action.payload.bookings,
      };
    case actionTypes.BOOKINGS_ADD_NEW:
      return produce(state, (draft) => {
        draft.list.push(action.payload.booking);
      });
    case actionTypes.BOOKINGS_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

const bookingsForDayDefaultState: BookingsForDayStateType = {
  loading: false,
  hasError: false,
  error: '',
  list: [],
};

const bookingsForDay = (state = bookingsForDayDefaultState, action: BookingsForDayActionType) => {
  switch (action.type) {
    case actionTypes.BOOKINGS_FOR_DAY_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.BOOKINGS_FOR_DAY_SUCCESS:
      return {
        ...getSuccessState(),
        list: action.payload.bookings,
      };
    case actionTypes.BOOKINGS_FOR_DAY_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    case actionTypes.BOOKINGS_FOR_DAY_CLEAR:
      return bookingsForDayDefaultState;
    default:
      return state;
  }
};

const bookingsAvailabilityState: BookingsAvailabilityStateType = {
  loading: false,
  hasError: false,
  error: '',
  data: {},
};

const availability = (
  state = bookingsAvailabilityState,
  action: BookingsAvailabilityActionType | BookingsAvailabilityClearActionType,
) => {
  switch (action.type) {
    case actionTypes.BOOKINGS_AVAILABILITY_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.BOOKINGS_AVAILABILITY_SUCCESS:
      return {
        ...getSuccessState(),
        data: action.payload.availability,
      };
    case actionTypes.BOOKINGS_AVAILABILITY_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    case actionTypes.BOOKINGS_AVAILABILITY_CLEAR:
      return bookingsAvailabilityState;
    default:
      return state;
  }
};

/************************* Bookings history *************************/
const bookingsHistoryPaginatedDefaultState: BookingsHistoryPaginatedStateType = {
  loading: false,
  error: '',
  hasError: false,
  pageSize: 1,
  pageNumber: 1,
  totalRecords: 0,
  hasMore: false,
  data: [],
};

const bookingsHistory = (
  state = bookingsHistoryPaginatedDefaultState,
  action: BookingsHistoryActionType,
): BookingsHistoryPaginatedStateType => {
  switch (action.type) {
    case actionTypes.BOOKINGS_HISTORY_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.BOOKINGS_HISTORY_SUCCESS:
      return {
        ...state,
        ...getSuccessState(),
        ...action.payload,
      };
    case actionTypes.BOOKINGS_HISTORY_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    case actionTypes.BOOKINGS_HISTORY_CLEAR:
      return bookingsHistoryPaginatedDefaultState;
    default:
      return state;
  }
};

export default combineReducers({
  all,
  bookingsForDay,
  availability,
  bookingsHistory,
});
