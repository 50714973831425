import { Database, Settings, User } from 'react-feather';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';
import { ReactSelectOptionsType, RolesType } from 'types/app';

export const showToast = (message: string, toastType: TypeOptions, toastOptions?: ToastOptions) => {
  switch (toastType) {
    case 'info':
      toast.info(message, toastOptions);
      break;
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'error':
      toast.error(message, toastOptions);
      break;
  }
};

export const roleMapper = (role: RolesType) => {
  switch (role) {
    case 'BusinessOwner':
      return {
        class: 'text-info',
        icon: User,
      };
    case 'HFUser':
      return {
        class: 'text-success',
        icon: Database,
      };
    default:
      return {
        class: 'text-warning',
        icon: Settings,
      };
  }
};

export const getBusinessHoursOptions = (): ReactSelectOptionsType[] => {
  const result: ReactSelectOptionsType[] = [];
  const totalHours = 24;
  const businessHoursInMin = 30;
  const divider = businessHoursInMin / 60;

  const endTime = (totalHours * businessHoursInMin) / divider;
  for (let i = 0; i < endTime; i += businessHoursInMin) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;
    const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padEnd(2, '0')}`;

    result.push({ value: time, label: time });
  }

  return result;
};
