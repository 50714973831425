export const APPS_PATH = 'apps';
const HOME_PATH = 'home';
const GLOBAL_PATH = 'global';

export const PATHS = {
  error: '/error',
  notAuthorized: '/not-authorized',
  auth: {
    login: '/login',
    register: '/register',
    resetPassword: '/resetpassword',
    forgotPassword: '/forgot-password',
  },
  // Menu
  home: {
    companies: `/${HOME_PATH}/companies`,
    dashboard: `/${HOME_PATH}/dashboard`,
  },
  apps: {
    dashboard: `/${APPS_PATH}/dashboard`,
    company: `/${APPS_PATH}/company`,
    companyUsers: `/${APPS_PATH}/company-users`,
    companyClients: `/${APPS_PATH}/company-clients`,
    bookings: `/${APPS_PATH}/bookings`,
    bookingsHistory: `/${APPS_PATH}/bookings-history`,
    entities: `/${APPS_PATH}/entities`,
    invoices: `/${APPS_PATH}/invoices`,
  },
  other: {
    global: {
      activities: `/${GLOBAL_PATH}/activities`,
      languages: `/${GLOBAL_PATH}/languages`,
    },
    reports: 'reports',
    adminUsers: 'admin-users',
  },
};
