import { DefaultStateType } from '../types/app';
import { ApiErrorType } from 'types/apiError';

const getLoadingState = (): DefaultStateType => ({
  loading: true,
  hasError: false,
  error: '',
});

const getSuccessState = (): DefaultStateType => ({
  loading: false,
  hasError: false,
  error: '',
});

const getFailureState = (error: ApiErrorType): DefaultStateType => ({
  loading: false,
  hasError: true,
  error: error?.errorDetail || error?.error || 'Something went wrong',
});

const assignSuccessState = (draft: DefaultStateType, successState: DefaultStateType) => {
  draft.loading = successState.loading;
  draft.hasError = successState.hasError;
  draft.error = successState.error;
};

const assignPaginationData = <T>(draft: PaginationType<T>, paginationData: PaginationType<T>) => {
  draft.pageSize = paginationData.pageSize;
  draft.pageNumber = paginationData.pageNumber;
  draft.totalRecords = paginationData.totalRecords;
  draft.hasMore = paginationData.hasMore;
  draft.data = paginationData.data;
};

const isSuccesfullResponse = (response: any = {}) => {
  return !('error' in response?.payload) && !response?.payload.isAxiosError;
};

export {
  getLoadingState,
  getSuccessState,
  getFailureState,
  assignSuccessState,
  isSuccesfullResponse,
  assignPaginationData,
};
