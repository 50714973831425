import Bugsnag from '@bugsnag/js';
import * as signalR from '@microsoft/signalr';
import React, { useCallback, useEffect, useRef } from 'react';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { tokenSelector } from 'redux/global/auth/selectors';
import { BookingCancelledType, BookingDeletedType } from 'views/apps/Bookings/types';

type SignalRMethods = 'RecurringProcessed' | 'NewBooking' | 'BookingDeleted' | 'BookingEdited' | 'BookingCancelled';

export const recurringBookingsSignalR = 'RecurringProcessed';
export const newBookingSignalR = 'NewBooking';
export const bookingDeletedSignalR = 'BookingDeleted';
export const bookingEditedSignalR = 'BookingEdited';
export const bookingCancelledSingalR = 'BookingCancelled';

export type SignalRContextType = {
  startRecurringBookingsSignalR: (callback: () => void) => void;
  startNewBookingSignalR: (callback: (bookingId: number) => void) => void;
  startBookingDeletedSignalR: (callback: (data: BookingDeletedType) => void) => void;
  startBookingEditedSignalR: (callback: (bookingId: number) => void) => void;
  startBookingCancelledSignalR: (callback: (data: BookingCancelledType) => void) => void;
  stopSignalRMethod: (methodName: SignalRMethods, callback?: (...args: any[]) => void) => void;
};

type Props = PropsWithChildren<{}>;

export const SignalRContext = React.createContext<SignalRContextType>({
  startRecurringBookingsSignalR: () => {},
  startNewBookingSignalR: () => {},
  startBookingDeletedSignalR: () => {},
  startBookingEditedSignalR: () => {},
  startBookingCancelledSignalR: () => {},
  stopSignalRMethod: () => {},
});

const SignalRProvider = ({ children }: Props) => {
  const connection = useRef<signalR.HubConnection>();
  const token = useSelector(tokenSelector);

  const startSignalRMethod = useCallback((methodName: SignalRMethods, callback: (...args: any[]) => void) => {
    if (!connection.current) {
      return;
    }
    connection.current.off(methodName, callback);
    connection.current.on(methodName, callback);
  }, []);

  const stopSignalRMethod = useCallback((methodName: SignalRMethods, callback?: (...args: any[]) => void) => {
    if (!connection.current) {
      return;
    }

    if (callback) {
      connection.current.off(methodName, callback);
    } else {
      connection.current.off(methodName);
    }
  }, []);

  const startRecurringBookingsSignalR = useCallback(
    (callback: () => void) => {
      startSignalRMethod(recurringBookingsSignalR, callback);
    },
    [startSignalRMethod],
  );

  const startNewBookingSignalR = useCallback(
    (callback: (bookingId: number) => void) => {
      startSignalRMethod(newBookingSignalR, callback);
    },
    [startSignalRMethod],
  );

  const startBookingDeletedSignalR = useCallback(
    (callback: (data: BookingDeletedType) => void) => {
      startSignalRMethod(bookingDeletedSignalR, callback);
    },
    [startSignalRMethod],
  );

  const startBookingEditedSignalR = useCallback(
    (callback: (bookingId: number) => void) => {
      startSignalRMethod(bookingEditedSignalR, callback);
    },
    [startSignalRMethod],
  );

  const startBookingCancelledSignalR = useCallback(
    (callback: (data: BookingCancelledType) => void) => {
      startSignalRMethod(bookingCancelledSingalR, callback);
    },
    [startSignalRMethod],
  );

  useEffect(() => {
    if (token) {
      connection.current = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_DOMAIN}/bookings`, {
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .build();
      connection.current.start().catch((e) => {
        Bugsnag.notify(e);
      });
    }
  }, [token]);

  return (
    <SignalRContext.Provider
      value={{
        startRecurringBookingsSignalR,
        stopSignalRMethod,
        startNewBookingSignalR,
        startBookingEditedSignalR,
        startBookingDeletedSignalR,
        startBookingCancelledSignalR,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;
