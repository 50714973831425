import { combineReducers } from 'redux';
import { getFailureState, getLoadingState, getSuccessState } from 'utility/redux';
import * as actionTypes from './actionTypes';
import { AmenitiesActionType, AmenitiesStateType } from './types';

/************************* Company amenities *************************/
const amenitiesState: AmenitiesStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const amenities = (state = amenitiesState, action: AmenitiesActionType): AmenitiesStateType => {
  switch (action.type) {
    case actionTypes.AMENITIES_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.AMENITIES_SUCCESS:
      return {
        ...state,
        ...getSuccessState(),
        list: action.payload.data,
      };
    case actionTypes.AMENITIES_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

export default combineReducers({
  amenities,
});
