import { accessTokenExists, getAccessToken } from '../../../utility/auth';
import * as actionTypes from './actionTypes';
import { AuthActionTypes, AuthStateType } from './types';

const authState: AuthStateType = {
  isAuthenticated: accessTokenExists(),
  token: getAccessToken(),
  loading: true,
};

export const auth = (state = authState, action: AuthActionTypes): AuthStateType => {
  switch (action.type) {
    case actionTypes.SET_USER_AUTHENTICATED:
      return {
        isAuthenticated: true,
        token: action.payload.token,
        loading: false,
      };
    case actionTypes.SET_USER_NOT_AUTHENTICATED:
      return {
        isAuthenticated: false,
        token: '',
        loading: false,
      };
    default:
      return state;
  }
};
