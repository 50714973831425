import { RootStateType } from 'redux/rootReducer';

export const appInitialized = (state: RootStateType) => !state.global.app.initializing;
export const appLanguageSelector = (state: RootStateType) => state.global.app.language;

/************************* Navbar *************************/
export const getNavbarTitle = (state: RootStateType) => state.global.navbar.title;

/************************* Spinner *************************/
export const showSpinnerSelector = (state: RootStateType) => state.global.spinner;
