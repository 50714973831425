export const BOOKINGS_LOADING = 'BOOKINGS_LOADING';
export const BOOKINGS_SUCCESS = 'BOOKINGS_SUCCESS';
export const BOOKINGS_FAILURE = 'BOOKINGS_FAILURE';
export const BOOKINGS_ADD_NEW = 'BOOKINGS_ADD_NEW';
/************************* Bookings for day *************************/
export const BOOKINGS_FOR_DAY_LOADING = 'BOOKINGS_FOR_DAY_LOADING';
export const BOOKINGS_FOR_DAY_SUCCESS = 'BOOKINGS_FOR_DAY_SUCCESS';
export const BOOKINGS_FOR_DAY_FAILURE = 'BOOKINGS_FOR_DAY_FAILURE';
export const BOOKINGS_FOR_DAY_CLEAR = 'BOOKINGS_FOR_DAY_CLEAR';
/************************* Bookings Availability *************************/
export const BOOKINGS_AVAILABILITY_LOADING = 'BOOKINGS_AVAILABILITY_LOADING';
export const BOOKINGS_AVAILABILITY_SUCCESS = 'BOOKINGS_AVAILABILITY_SUCCESS';
export const BOOKINGS_AVAILABILITY_FAILURE = 'BOOKINGS_AVAILABILITY_FAILURE';
export const BOOKINGS_AVAILABILITY_CLEAR = 'BOOKINGS_AVAILABILITY_CLEAR';
/************************* Bookings History *************************/
export const BOOKINGS_HISTORY_LOADING = 'BOOKINGS_HISTORY_LOADING';
export const BOOKINGS_HISTORY_SUCCESS = 'BOOKINGS_HISTORY_SUCCESS';
export const BOOKINGS_HISTORY_FAILURE = 'BOOKINGS_HISTORY_FAILURE';
export const BOOKINGS_HISTORY_CLEAR = 'BOOKINGS_HISTORY_CLEAR';
