import { combineReducers } from 'redux';
import { UserInfoActionType, UserInfoStateType } from './types';
import * as actionTypes from './actionTypes';
import { getLoadingState, getSuccessState, getFailureState } from 'utility/redux';
import { RegistrationEnum } from 'types/enums';

const userInfoState: UserInfoStateType = {
  loading: false,
  hasError: false,
  error: '',
  data: {
    email: '',
    companyRoles: [],
    registrationType: RegistrationEnum.HaveFun,
    memberSince: '',
    phoneNumberConfirmed: false,
  },
};

const userInfo = (state = userInfoState, action: UserInfoActionType) => {
  switch (action.type) {
    case actionTypes.USER_INFO_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.USER_INFO_SUCCESS: {
      return {
        data: action.payload.data,
        ...getSuccessState(),
      };
    }
    case actionTypes.USER_INFO_FAILURE: {
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  userInfo,
});
