import { ACTION_READ, SUBJECT_APPS, SUBJECT_SUPER_USER_MANAGEMENT } from 'constants/casl';
import { PATHS } from 'constants/paths';
import { Briefcase, Calendar, FileText, Grid, Layout, List, User, Users } from 'react-feather';
import { MenuItemType } from 'redux/global/menu/types';

export const APPS_HEADER_ID = 'header_apps';
export const APPS_DASHBOARD = 'appsDashboard';
export const APPS_CALENDAR = 'appsCalendar';
export const APPS_CONTACTS = 'appsContacts';
export const APPS_FIELDS = 'appsFields';
export const APPS_BUSINESS_DETAILS = 'appsBusinessDetails';
export const APPS_COMPANY_USERS = 'appsCompanyUsers';
export const APPS_BOOKINGS_HISTORY = 'appsBookingsHistory';
export const APPS_INVOICES = 'appsInvoices';

const navigation: MenuItemType[] = [
  {
    id: APPS_HEADER_ID,
    header: 'apps.header',
    action: ACTION_READ,
    resource: SUBJECT_APPS,
  },
  // {
  //   id: APPS_DASHBOARD,
  //   title: 'apps.dashboard',
  //   type: 'collapse',
  //   icon: <Layout size={20} />,
  //   navLink: PATHS.apps.dashboard,
  //   action: ACTION_READ,
  //   resource: SUBJECT_APPS,
  // },
  {
    id: APPS_CALENDAR,
    title: 'apps.calendar',
    type: 'collapse',
    icon: <Calendar size={20} />,
    navLink: PATHS.apps.bookings,
    action: ACTION_READ,
    resource: SUBJECT_APPS,
  },
  {
    id: APPS_CONTACTS,
    title: 'apps.contacts',
    type: 'collapse',
    icon: <User size={20} />,
    navLink: PATHS.apps.companyClients,
    action: ACTION_READ,
    resource: SUBJECT_APPS,
  },
  {
    id: APPS_FIELDS,
    title: 'apps.fields',
    type: 'collapse',
    icon: <Grid size={20} />,
    navLink: PATHS.apps.entities,
    action: ACTION_READ,
    resource: SUBJECT_APPS,
  },
  {
    id: APPS_BUSINESS_DETAILS,
    title: 'apps.businessDetails',
    type: 'collapse',
    icon: <Briefcase size={20} />,
    navLink: PATHS.apps.company,
    action: ACTION_READ,
    resource: SUBJECT_APPS,
  },
  {
    id: APPS_COMPANY_USERS,
    title: 'apps.userManagement',
    type: 'collapse',
    icon: <Users size={20} />,
    navLink: PATHS.apps.companyUsers,
    action: ACTION_READ,
    resource: SUBJECT_APPS,
  },
  {
    id: APPS_BOOKINGS_HISTORY,
    title: 'apps.bookingsHistory',
    type: 'collapse',
    icon: <List size={20} />,
    navLink: PATHS.apps.bookingsHistory,
    action: ACTION_READ,
    resource: SUBJECT_APPS,
  },
  {
    id: APPS_INVOICES,
    title: 'apps.invoices',
    type: 'collapse',
    icon: <FileText size={20} />,
    navLink: PATHS.apps.invoices,
    action: ACTION_READ,
    resource: SUBJECT_SUPER_USER_MANAGEMENT,
  },
];

export default navigation;
