import {
  EntityActionType,
  EntityFormSetLanguageCodeType,
  EntityFormType as EntityFormStateType,
  EntityStateType,
} from './types';
import * as actionTypes from './actionTypes';
import { combineReducers } from 'redux';
import { getLoadingState, getSuccessState, getFailureState } from 'utility/redux';
import { DEFAULT_REQUEST_LANGUAGE } from 'constants/app';

const entityDefaultState: EntityStateType = {
  loading: false,
  error: '',
  hasError: false,
  data: {
    entityId: -1,
    companyId: -1,
    entityCategoryId: -1,
    publishStatus: 1,
    colourId: -1,
    entityTranslations: [],
    entityPricingOptions: [],
    isActive: false,
    datetimeInserted: '',
    datetimeDeleted: '',
    datetimeUpdated: '',
  },
};

const entity = (state = entityDefaultState, action: EntityActionType) => {
  switch (action.type) {
    case actionTypes.ENTITY_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.ENTITY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        ...getSuccessState(),
      };
    case actionTypes.ENTITY_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    case actionTypes.ENTITY_CLEAR:
      return {
        ...entityDefaultState,
      };
    default:
      return state;
  }
};

/************************* Entity Form *************************/
const entityFormDefaultState: EntityFormStateType = {
  languageCode: DEFAULT_REQUEST_LANGUAGE,
};
const entityForm = (state = entityFormDefaultState, action: EntityFormSetLanguageCodeType): EntityFormStateType => {
  switch (action.type) {
    case actionTypes.SET_ENTITY_FORM_LANGUAGE_CODE: {
      return {
        languageCode: action.payload.languageCode,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  entity,
  entityForm,
});
