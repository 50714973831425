import { combineReducers } from 'redux';
// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import global from './global/globalReducers';
import activitiesCombined from './activities/reducers';
import languages from './languages/reducers';
import companiesCombined from './companies/reducers';
import common from './common/reducers';
import { countries } from './countries/reducers';
import entityCategoriesCombined from './entityCategories/reducers';
import entitiesCombined from './entities/reducers';
import bookingsCombined from './bookings/reducers';
import facilitiesCombined from './facilities/reducers';
import amenitiesCombined from './amenities/reducers';
import accountCombined from './account/reducers';

const rootReducer = combineReducers({
  activitiesCombined,
  auth,
  companiesCombined,
  common,
  countries,
  entityCategoriesCombined,
  entitiesCombined,
  bookingsCombined,
  global,
  languages,
  layout,
  navbar,
  facilitiesCombined,
  amenitiesCombined,
  accountCombined,
});

export type RootStateType = ReturnType<typeof rootReducer>;

export default rootReducer;
