import { combineReducers } from 'redux';
import { getFailureState, getLoadingState, getSuccessState } from 'utility/redux';
import * as actionTypes from './actionTypes';
import {
  ColoursActionType,
  ColoursStateType,
  CountryPhonePrefixesActionType,
  GetRolesActionType,
  RolesStateType,
} from './types';
import { DefaultStateType } from 'types/app';
import { CountryPhonePrefixType } from 'types/api/common';

const rolesDefaultState: RolesStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const roles = (state = rolesDefaultState, action: GetRolesActionType): RolesStateType => {
  switch (action.type) {
    case actionTypes.GET_ROLES_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.GET_ROLES_SUCCESS: {
      return {
        ...state,
        list: action.payload.roles,
        ...getSuccessState(),
      };
    }
    case actionTypes.GET_ROLES_FAILURE: {
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    }
    default: {
      return state;
    }
  }
};

const coloursDefaultState: ColoursStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const colours = (state = coloursDefaultState, action: ColoursActionType): ColoursStateType => {
  switch (action.type) {
    case actionTypes.COLOURS_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.COLOURS_SUCCESS: {
      return {
        ...state,
        list: action.payload.colours,
        ...getSuccessState(),
      };
    }
    case actionTypes.COLOURS_FAILURE: {
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    }
    default: {
      return state;
    }
  }
};

/************************* Country phone prefixes *************************/
type CountryPhonePrefixesStateType = DefaultStateType & {
  list: CountryPhonePrefixType[];
};
const countryPhonePrefixesDefaultState: CountryPhonePrefixesStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const countryPhonePrefixes = (
  state = countryPhonePrefixesDefaultState,
  action: CountryPhonePrefixesActionType,
): CountryPhonePrefixesStateType => {
  switch (action.type) {
    case actionTypes.COUNTRY_PHONE_PREFIXES_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.COUNTRY_PHONE_PREFIXES_SUCCESS: {
      return {
        ...state,
        list: action.payload.countryPhonePrefixes,
        ...getSuccessState(),
      };
    }
    case actionTypes.COUNTRY_PHONE_PREFIXES_FAILURE: {
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({ roles, colours, countryPhonePrefixes });
