import { combineReducers } from 'redux';
import { getLoadingState, assignSuccessState, getSuccessState, getFailureState } from 'utility/redux';
import { FacilitiesActionType, FacilitiesStateType } from './types';
import * as actionTypes from './actionTypes';

/************************* Company facilities *************************/
const facilitiesState: FacilitiesStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const facilities = (state = facilitiesState, action: FacilitiesActionType): FacilitiesStateType => {
  switch (action.type) {
    case actionTypes.FACILITIES_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.FACILITIES_SUCCESS:
      return {
        ...state,
        ...getSuccessState(),
        list: action.payload.data,
      };
    case actionTypes.FACILITIES_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

export default combineReducers({
  facilities,
});
