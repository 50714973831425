export enum DayOfWeekEnum {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
}

export enum PublishStatusEnum {
  draft = 1,
  preview,
  public,
}

export enum SortOrderEnum {
  asc = 1,
  desc,
}

/************************* Company users *************************/
export enum CompanyUsersSortByEnum {
  surname = 1,
  since,
}

/************************* Company clients *************************/
export enum CompanyClientsSortByEnum {
  surname = 1,
  datetimeInserted,
  bookings,
}

export enum RegistrationEnum {
  HaveFun = 1,
  Facebook,
  Google,
  Apple,
}

export enum ContactTypeEnum {
  Email = 1,
  MobilePhone,
}

/************************* Company teams *************************/
export enum CompanyTeamsSortByEnum {
  name = 1,
  totalBookings,
}

/************************* Academies *************************/
export enum CompanyAcademiesSortByEnum {
  name = 1,
  totalBookings,
}

/************************* Social login *************************/
export enum LoginProvidersEnum {
  HaveFun = 1,
  Facebook,
  Google,
  Apple,
}

/************************* Bookings *************************/
export enum BookingTypeEnum {
  Other,
  Friendly,
  Tournament,
  Training,
}

export enum RecurringBookingStatusEnum {
  Pending = 0,
  Active,
  Cancelled,
}

export enum CancellationReasonVisibilityEnum {
  None,
  Business,
  Client,
  Both,
}

export enum AppClientEnum {
  suite = 1,
  suitePocket,
  haveFun,
}

/************************* Exceptions *************************/
export enum ExceptionTypeEnum {
  Other,
  Tournament,
  Training,
  Maintenance,
  PublicHoliday,
  Party,
}

export enum ExceptionCategoryEnum {
  DateAndTime,
  Range,
  Recurring,
}
