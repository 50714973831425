export const GET_ACTIVITIES_LOADING = 'GET_ACTIVITIES_LOADING';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_EXISTING = 'GET_ACTIVITIES_EXISTING';
export const GET_ACTIVITIES_FAILURE = 'GET_ACTIVITIES_FAILURE';
export const CREATE_ACTIVITY_LOADING = 'CREATE_ACTIVITY_LOADING';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_FAILURE = 'CREATE_ACTIVITY_FAILURE';
export const UPDATE_ACTIVITY_LOADING = 'UPDATE_ACTIVITY_LOADING';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';
export const SET_SELECTED_ACTIVITY_ID = 'SET_SELECTED_ACTIVITY_ID';
export const CREATE_ACTIVITY_TRANSLATION_LOADING = 'CREATE_ACTIVITY_TRANSLATION_LOADING';
export const CREATE_ACTIVITY_TRANSLATION_SUCCESS = 'CREATE_ACTIVITY_TRANSLATION_SUCCESS';
export const CREATE_ACTIVITY_TRANSLATION_FAILURE = 'CREATE_ACTIVITY_TRANSLATION_FAILURE';
export const UPDATE_ACTIVITY_TRANSLATION_LOADING = 'UPDATE_ACTIVITY_TRANSLATION_LOADING';
export const UPDATE_ACTIVITY_TRANSLATION_SUCCESS = 'UPDATE_ACTIVITY_TRANSLATION_SUCCESS';
export const UPDATE_ACTIVITY_TRANSLATION_FAILURE = 'UPDATE_ACTIVITY_TRANSLATION_FAILURE';
export const CREATE_RATING_QUESTION_LOADING = 'CREATE_RATING_QUESTION_LOADING';
export const CREATE_RATING_QUESTION_SUCCESS = 'CREATE_RATING_QUESTION_SUCCESS';
export const CREATE_RATING_QUESTION_FAILURE = 'CREATE_RATING_QUESTION_FAILURE';
export const UPDATE_RATING_QUESTION_LOADING = 'UPDATE_RATING_QUESTION_LOADING';
export const UPDATE_RATING_QUESTION_SUCCESS = 'UPDATE_RATING_QUESTION_SUCCESS';
export const UPDATE_RATING_QUESTION_FAILURE = 'UPDATE_RATING_QUESTION_FAILURE';
export const SET_SELECTED_RATING_QUESTION = 'SET_SELECTED_RATING_QUESTION';
export const CREATE_RATING_QUESTION_TRANSLATION_LOADING = 'CREATE_RATING_QUESTION_TRANSLATION_LOADING';
export const CREATE_RATING_QUESTION_TRANSLATION_SUCCESS = 'CREATE_RATING_QUESTION_TRANSLATION_SUCCESS';
export const CREATE_RATING_QUESTION_TRANSLATION_FAILURE = 'CREATE_RATING_QUESTION_TRANSLATION_FAILURE';
export const UPDATE_RATING_QUESTION_TRANSLATION_LOADING = 'UPDATE_RATING_QUESTION_TRANSLATION_LOADING';
export const UPDATE_RATING_QUESTION_TRANSLATION_SUCCESS = 'UPDATE_RATING_QUESTION_TRANSLATION_SUCCESS';
export const UPDATE_RATING_QUESTION_TRANSLATION_FAILURE = 'UPDATE_RATING_QUESTION_TRANSLATION_FAILURE';
export const CREATE_ENTITY_CATEGORY_LOADING = 'CREATE_ENTITY_CATEGORY_LOADING';
export const CREATE_ENTITY_CATEGORY_SUCCESS = 'CREATE_ENTITY_CATEGORY_SUCCESS';
export const CREATE_ENTITY_CATEGORY_FAILURE = 'CREATE_ENTITY_CATEGORY_FAILURE';
export const UPDATE_ENTITY_CATEGORY_LOADING = 'UPDATE_ENTITY_CATEGORY_LOADING';
export const UPDATE_ENTITY_CATEGORY_SUCCESS = 'UPDATE_ENTITY_CATEGORY_SUCCESS';
export const UPDATE_ENTITY_CATEGORY_FAILURE = 'UPDATE_ENTITY_CATEGORY_FAILURE';
export const SET_SELECTED_ENTITY_CATEGORY = 'SET_SELECTED_ENTITY_CATEGORY';
export const CREATE_ENTITY_CATEGORY_TRANSLATION_LOADING = 'CREATE_ENTITY_CATEGORY_TRANSLATION_LOADING';
export const CREATE_ENTITY_CATEGORY_TRANSLATION_SUCCESS = 'CREATE_ENTITY_CATEGORY_TRANSLATION_SUCCESS';
export const CREATE_ENTITY_CATEGORY_TRANSLATION_FAILURE = 'CREATE_ENTITY_CATEGORY_TRANSLATION_FAILURE';
export const UPDATE_ENTITY_CATEGORY_TRANSLATION_LOADING = 'UPDATE_ENTITY_CATEGORY_TRANSLATION_LOADING';
export const UPDATE_ENTITY_CATEGORY_TRANSLATION_SUCCESS = 'UPDATE_ENTITY_CATEGORY_TRANSLATION_SUCCESS';
export const UPDATE_ENTITY_CATEGORY_TRANSLATION_FAILURE = 'UPDATE_ENTITY_CATEGORY_TRANSLATION_FAILURE';
/************************* Duration options *************************/
export const DURATION_OPTIONS_LOADING = 'DURATION_OPTIONS_LOADING';
export const DURATION_OPTIONS_SUCCESS = 'DURATION_OPTIONS_SUCCESS';
export const DURATION_OPTIONS_FAILURE = 'DURATION_OPTIONS_FAILURE';
/************************* Divisions *************************/
export const DIVISIONS_LOADING = 'DIVISIONS_LOADING';
export const DIVISIONS_SUCCESS = 'DIVISIONS_SUCCESS';
export const DIVISIONS_FAILURE = 'DIVISIONS_FAILURE';
