import { ACTION_MANAGE, SUBJECT_ACTIVITY, SUBJECT_LANGUAGE, SUBJECT_OTHER } from 'constants/casl';
import { PATHS } from 'constants/paths';
import { Circle, FileText, Globe, User } from 'react-feather';
import { MenuItemType } from 'redux/global/menu/types';

const navigation: MenuItemType[] = [
  { header: 'other.header', action: ACTION_MANAGE, resource: SUBJECT_OTHER },
  {
    id: 'navigation_global',
    title: 'other.globalEntities.index',
    type: 'collapse',
    icon: <Globe size={20} />,
    children: [
      {
        id: 'navigation_global_activities',
        title: 'other.globalEntities.activities',
        type: 'item',
        icon: <Circle size={12} />,
        navLink: PATHS.other.global.activities,
        action: ACTION_MANAGE,
        resource: SUBJECT_ACTIVITY,
      },
      {
        id: 'navigation_global_languages',
        title: 'other.globalEntities.languages',
        type: 'item',
        icon: <Circle size={12} />,
        navLink: PATHS.other.global.languages,
        action: ACTION_MANAGE,
        resource: SUBJECT_LANGUAGE,
      },
    ],
  },
  {
    id: 'navigation_other_reports',
    title: 'other.reports',
    type: 'collapse',
    icon: <FileText size={20} />,
    navLink: PATHS.other.reports,
  },
  {
    id: 'navigation_other_admin_users',
    title: 'other.adminUsers',
    type: 'collapse',
    icon: <User size={20} />,
    navLink: PATHS.other.adminUsers,
  },
];

export default navigation;
