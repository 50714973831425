import { Ability, AbilityBuilder } from '@casl/ability';
import { ROLE_BUSINESS_ADMIN, ROLE_BUSINESS_USER, ROLE_SUPERUSER } from 'constants/app';
import {
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_MANAGE,
  ACTION_READ,
  ACTION_UPDATE,
  SUBJECT_ACTIVITY,
  SUBJECT_ALL,
  SUBJECT_APPS,
  SUBJECT_BOOKINGS,
  SUBJECT_BOOKINGS_SYSTEM,
  SUBJECT_COMPANY,
  SUBJECT_COMPANY_AMENITIES,
  SUBJECT_COMPANY_ENTITIES,
  SUBJECT_COMPANY_EXCEPTIONS,
  SUBJECT_COMPANY_FACILITIES,
  SUBJECT_COMPANY_HOURS,
  SUBJECT_COMPANY_INFO,
  SUBJECT_COMPANY_MEDIA,
  SUBJECT_CONTACTS,
  SUBJECT_FINANCIAL_INFO,
  SUBJECT_HOME,
  SUBJECT_INVITE_BUSINESS_OWNERS,
  SUBJECT_INVITE_USERS,
  SUBJECT_INVOICES,
  SUBJECT_LANGUAGE,
  SUBJECT_OTHER,
  SUBJECT_SUPER_USER_MANAGEMENT,
} from 'constants/casl';
import { UserInfoType } from 'types/api/account';
import { CompanyType } from 'types/api/companies';

// Manage is for all actions
export type CASLActionsType =
  | typeof ACTION_CREATE
  | typeof ACTION_READ
  | typeof ACTION_UPDATE
  | typeof ACTION_DELETE
  | typeof ACTION_MANAGE;
export type CASLSubjectsType =
  | typeof SUBJECT_ALL
  | typeof SUBJECT_HOME
  | typeof SUBJECT_APPS
  | typeof SUBJECT_OTHER
  | typeof SUBJECT_COMPANY
  | typeof SUBJECT_ACTIVITY
  | typeof SUBJECT_LANGUAGE
  | typeof SUBJECT_COMPANY_INFO
  | typeof SUBJECT_COMPANY_HOURS
  | typeof SUBJECT_COMPANY_EXCEPTIONS
  | typeof SUBJECT_COMPANY_AMENITIES
  | typeof SUBJECT_COMPANY_FACILITIES
  | typeof SUBJECT_COMPANY_MEDIA
  | typeof SUBJECT_COMPANY_ENTITIES
  | typeof SUBJECT_INVITE_BUSINESS_OWNERS
  | typeof SUBJECT_INVITE_USERS
  | typeof SUBJECT_BOOKINGS
  | typeof SUBJECT_BOOKINGS_SYSTEM
  | typeof SUBJECT_INVOICES
  | typeof SUBJECT_FINANCIAL_INFO
  | typeof SUBJECT_CONTACTS
  | typeof SUBJECT_SUPER_USER_MANAGEMENT;

const { can, rules, build } = new AbilityBuilder(Ability);
can(ACTION_READ, SUBJECT_HOME);

export const updateAbilityForUser = (ability: Ability, user: UserInfoType, company?: CompanyType) => {
  if (user) {
    for (let i = 0; i < user.roles.length; i++) {
      const role = user.roles[i];

      if (
        role.name !== ROLE_SUPERUSER &&
        role.companyIds.length > 0 &&
        (!company?.companyId || !role.companyIds.includes(company.companyId))
      ) {
        continue;
      }
      if (role.name === ROLE_SUPERUSER) {
        can(ACTION_CREATE, SUBJECT_COMPANY);
        can(ACTION_MANAGE, SUBJECT_OTHER);
        can(ACTION_MANAGE, SUBJECT_ACTIVITY);
        can(ACTION_MANAGE, SUBJECT_LANGUAGE);
        can(ACTION_MANAGE, SUBJECT_COMPANY_INFO);
        can(ACTION_MANAGE, SUBJECT_COMPANY_HOURS);
        can(ACTION_MANAGE, SUBJECT_COMPANY_EXCEPTIONS);
        can(ACTION_MANAGE, SUBJECT_COMPANY_MEDIA);
        can(ACTION_MANAGE, SUBJECT_COMPANY_AMENITIES);
        can(ACTION_MANAGE, SUBJECT_COMPANY_FACILITIES);
        can(ACTION_MANAGE, SUBJECT_COMPANY_ENTITIES);
        can(ACTION_MANAGE, SUBJECT_INVITE_BUSINESS_OWNERS);
        can(ACTION_MANAGE, SUBJECT_INVITE_USERS);
        can(ACTION_MANAGE, SUBJECT_SUPER_USER_MANAGEMENT);
        can(ACTION_READ, SUBJECT_BOOKINGS);
        can(ACTION_UPDATE, SUBJECT_BOOKINGS_SYSTEM);
        can(ACTION_READ, SUBJECT_FINANCIAL_INFO);
        can(ACTION_READ, SUBJECT_INVOICES);
        can(ACTION_READ, SUBJECT_APPS);
      }
      if (role.name === ROLE_BUSINESS_ADMIN) {
        can(ACTION_MANAGE, SUBJECT_INVITE_USERS);
        can(ACTION_MANAGE, SUBJECT_BOOKINGS);
        can(ACTION_MANAGE, SUBJECT_COMPANY_EXCEPTIONS);
        can(ACTION_MANAGE, SUBJECT_INVITE_BUSINESS_OWNERS);
        can(ACTION_READ, SUBJECT_FINANCIAL_INFO);
        can(ACTION_MANAGE, SUBJECT_CONTACTS);
        can(ACTION_READ, SUBJECT_APPS);
      }
      if (role.name === ROLE_BUSINESS_USER) {
        can(ACTION_MANAGE, SUBJECT_BOOKINGS);
        can(ACTION_MANAGE, SUBJECT_CONTACTS);
        can(ACTION_READ, SUBJECT_APPS);
      }
    }
  }

  ability.update(rules);
};

export default build();
