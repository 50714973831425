import { combineReducers } from 'redux';
import { auth } from './auth/reducers';
import { app, navbar, spinner } from './reducers';
import menu from './menu/reducers';

const globalState = combineReducers({
  app,
  navbar,
  spinner,
  auth,
  menu,
});

export type GlobalStateType = ReturnType<typeof globalState>;

export default globalState;
