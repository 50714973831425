import { DEFAULT_REQUEST_LANGUAGE } from 'constants/app';
import produce from 'immer';
import { combineReducers } from 'redux';
import { assignSuccessState, getFailureState, getLoadingState, getSuccessState } from 'utility/redux';
import * as actionTypes from './actionTypes';
import {
  ChangeCompanyLogoActionType,
  CompaniesPaginatedStateType,
  CompanyAlertsActionType,
  CompanyAlertsStateType,
  CompanyAmenitiesActionType,
  CompanyAmenitiesFormActionType,
  CompanyAmenitiesFormStateType,
  CompanyAmenitiesStateType,
  CompanyClientsActionType,
  CompanyClientsPaginatedStateType,
  CompanyCoverPhotoState,
  CompanyDetailsFormStateType,
  CompanyFacilitiesActionType,
  CompanyFacilitiesFormActionType,
  CompanyFacilitiesFormStateType,
  CompanyFacilitiesStateType,
  CompanyLogoState,
  CompanyMediaStateType,
  CompanyTopViewActionType,
  CompanyTopViewState,
  CompanyUserInvitationsActionType,
  CompanyUserInvitationsPaginatedStateType,
  CompanyUsersActionType,
  CompanyUsersPaginatedStateType,
  DeleteCompanyCoverPhotoActionType,
  DeleteCompanyMediaActionType,
  EntitiesStateType,
  GetCompaniesActionType,
  GetCompanyActionType,
  GetCompanyCoverPhotoActionType,
  GetCompanyLogoActionType,
  GetCompanyMediaActionType,
  GetCompanyStateType,
  GetEntitiesActionType,
  GetOpeningHoursActionType,
  OpeningHoursExceptionsActionType,
  OpeningHoursExceptionsState,
  OpeningHoursState,
  PostCompanyCoverPhotoActionType,
  PostCompanyDetailsActionType,
  PostCompanyMediaActionType,
  PostCompanyTopViewActionType,
  PostOpeningHoursActionType,
  SetSelectedCompanyActionType,
} from './types';

const companiesDefaultState: CompaniesPaginatedStateType = {
  loading: false,
  error: '',
  hasError: false,
  pageSize: 1,
  pageNumber: 1,
  totalRecords: 0,
  hasMore: false,
  data: [],
};

const companiesPaginated = (
  state = companiesDefaultState,
  action: GetCompaniesActionType | GetCompanyActionType,
): CompaniesPaginatedStateType => {
  switch (action.type) {
    case actionTypes.GET_COMPANIES_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...getSuccessState(),
        ...action.payload,
      };
    case actionTypes.GET_COMPANIES_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    case actionTypes.CLEAR_COMPANIES:
      return {
        ...companiesDefaultState,
      };
    default:
      return state;
  }
};

/************************* Company *************************/
const companyDefaultState: GetCompanyStateType = {
  loading: false,
  error: '',
  hasError: false,
  data: undefined,
};

const selectedCompany = (
  state = companyDefaultState,
  action: SetSelectedCompanyActionType | GetCompanyActionType | PostCompanyDetailsActionType,
): GetCompanyStateType => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_LOADING:
    case actionTypes.POST_COMPANY_DETAILS_LOADING:
      return {
        ...getLoadingState(),
      };
    case actionTypes.SET_SELECTED_COMPANY:
      return {
        ...state,
        data: action.payload.company,
      };
    case actionTypes.GET_COMPANY_SUCCESS:
    case actionTypes.POST_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        ...getSuccessState(),
      };
    case actionTypes.GET_COMPANY_FAILURE:
    case actionTypes.POST_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

const DATE_START_TIME = '00:00';

const openeingHoursState: OpeningHoursState = {
  data: {
    monday: [{ openTime: DATE_START_TIME, duration: 0 }],
    tuesday: [{ openTime: DATE_START_TIME, duration: 0 }],
    wednesday: [{ openTime: DATE_START_TIME, duration: 0 }],
    thursday: [{ openTime: DATE_START_TIME, duration: 0 }],
    friday: [{ openTime: DATE_START_TIME, duration: 0 }],
    saturday: [{ openTime: DATE_START_TIME, duration: 0 }],
    sunday: [{ openTime: DATE_START_TIME, duration: 0 }],
  },
  loading: false,
  error: '',
  hasError: false,
};

const openingHours = (state = openeingHoursState, action: GetOpeningHoursActionType | PostOpeningHoursActionType) => {
  switch (action.type) {
    case actionTypes.GET_OPENING_HOURS_LOADING:
    case actionTypes.POST_OPENING_HOURS_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.GET_OPENING_HOURS_SUCCESS:
    case actionTypes.POST_OPENING_HOURS_SUCCESS:
      return produce(state, (draft) => {
        draft.data = action.payload.data.openingHours;
        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.GET_OPENING_HOURS_FAILURE:
    case actionTypes.POST_OPENING_HOURS_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

/************************* Opening hours exceptions *************************/
const openeingHoursExceptionsState: OpeningHoursExceptionsState = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const openingHoursExceptions = (state = openeingHoursExceptionsState, action: OpeningHoursExceptionsActionType) => {
  switch (action.type) {
    case actionTypes.OPENING_HOURS_EXCEPTIONS_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.OPENING_HOURS_EXCEPTIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.list = action.payload.data;
        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.OPENING_HOURS_EXCEPTIONS_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

/************************* Details form *************************/
const detailsFormDefaultState: CompanyDetailsFormStateType = {
  languageCode: DEFAULT_REQUEST_LANGUAGE,
};

const detailsForm = (state = detailsFormDefaultState, action: DetailsFormActionType): CompanyDetailsFormStateType => {
  switch (action.type) {
    case actionTypes.SET_COMPANY_DETAILS_FORM_LANGUAGE_CODE:
      return {
        ...state,
        languageCode: action.payload.languageCode,
      };
    default:
      return state;
  }
};

/************************* Company logo *************************/
const companyLogoDefaultState: CompanyLogoState = {
  loading: false,
  error: '',
  hasError: false,
  companyMediaId: 0,
  mediaType: 0,
  url: '',
  order: 0,
};

const logo = (
  state = companyLogoDefaultState,
  action: ChangeCompanyLogoActionType | GetCompanyLogoActionType,
): CompanyLogoState => {
  switch (action.type) {
    case actionTypes.CHANGE_COMPANY_LOGO_LOADING:
    case actionTypes.GET_COMPANY_LOGO_LOADING:
      return {
        ...state,
        url: '',
        ...getLoadingState(),
      };
    case actionTypes.CHANGE_COMPANY_LOGO_SUCCESS:
    case actionTypes.GET_COMPANY_LOGO_SUCCESS:
      return produce(state, (draft) => {
        draft.companyMediaId = action.payload.data.companyMediaId;
        draft.mediaType = action.payload.data.mediaType;
        draft.order = action.payload.data.order;
        draft.url = action.payload.data.url;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.CHANGE_COMPANY_LOGO_FAILURE:
    case actionTypes.GET_COMPANY_LOGO_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

/************************* Company Cover Photo *************************/
const companyCoverPhotoDefaultState: CompanyCoverPhotoState = {
  loading: false,
  error: '',
  hasError: false,
  companyMediaId: 0,
  mediaType: 0,
  url: '',
  order: 0,
};

const coverPhoto = (
  state = companyCoverPhotoDefaultState,
  action: PostCompanyCoverPhotoActionType | GetCompanyCoverPhotoActionType | DeleteCompanyCoverPhotoActionType,
): CompanyLogoState => {
  switch (action.type) {
    case actionTypes.POST_COMPANY_COVER_PHOTO_LOADING:
    case actionTypes.GET_COMPANY_COVER_PHOTO_LOADING:
    case actionTypes.DELETE_COMPANY_COVER_PHOTO_LOADING:
      return {
        ...state,
        url: '',
        ...getLoadingState(),
      };
    case actionTypes.POST_COMPANY_COVER_PHOTO_SUCCESS:
    case actionTypes.GET_COMPANY_COVER_PHOTO_SUCCESS:
      return produce(state, (draft) => {
        draft.companyMediaId = action.payload.data.companyMediaId;
        draft.mediaType = action.payload.data.mediaType;
        draft.order = action.payload.data.order;
        draft.url = action.payload.data.url;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.DELETE_COMPANY_COVER_PHOTO_SUCCESS: {
      return {
        ...companyCoverPhotoDefaultState,
      };
    }
    case actionTypes.POST_COMPANY_COVER_PHOTO_FAILURE:
    case actionTypes.GET_COMPANY_COVER_PHOTO_FAILURE:
    case actionTypes.DELETE_COMPANY_COVER_PHOTO_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

/************************* Media *************************/
const companyMediaDefaultState: CompanyMediaStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const media = (
  state = companyMediaDefaultState,
  action: GetCompanyMediaActionType | PostCompanyMediaActionType | DeleteCompanyMediaActionType,
): CompanyMediaStateType => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_MEDIA_LOADING:
    case actionTypes.POST_COMPANY_MEDIA_LOADING:
    case actionTypes.DELETE_COMPANY_MEDIA_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.GET_COMPANY_MEDIA_SUCCESS:
      return produce(state, (draft) => {
        draft.list = action.payload.data;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.POST_COMPANY_MEDIA_SUCCESS:
      return produce(state, (draft) => {
        draft.list.push(action.payload.data);

        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.DELETE_COMPANY_MEDIA_SUCCESS:
      return produce(state, (draft) => {
        draft.list = action.payload.data;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.GET_COMPANY_MEDIA_FAILURE:
    case actionTypes.POST_COMPANY_MEDIA_FAILURE:
    case actionTypes.DELETE_COMPANY_MEDIA_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

/************************* Company Users *************************/
const companyUsersPaginatedDefaultState: CompanyUsersPaginatedStateType = {
  loading: false,
  error: '',
  hasError: false,
  pageSize: 1,
  pageNumber: 1,
  totalRecords: 0,
  hasMore: false,
  data: [],
};

const companyUsersPaginated = (
  state = companyUsersPaginatedDefaultState,
  action: CompanyUsersActionType,
): CompanyUsersPaginatedStateType => {
  switch (action.type) {
    case actionTypes.COMPANY_USERS_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.COMPANY_USERS_SUCCESS:
      return {
        ...state,
        ...getSuccessState(),
        ...action.payload,
      };
    case actionTypes.COMPANY_USERS_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

/************************* Company User Invitations *************************/
const companyUsersInvitationsDefaultState: CompanyUserInvitationsPaginatedStateType = {
  loading: false,
  error: '',
  hasError: false,
  pageSize: 1,
  pageNumber: 1,
  totalRecords: 0,
  hasMore: false,
  data: [],
};

const companyUserInvitationsPaginated = (
  state = companyUsersInvitationsDefaultState,
  action: CompanyUserInvitationsActionType,
): CompanyUserInvitationsPaginatedStateType => {
  switch (action.type) {
    case actionTypes.COMPANY_USER_INVITATIONS_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.COMPANY_USER_INVITATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        ...getSuccessState(),
      };
    case actionTypes.COMPANY_USER_INVITATIONS_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

const entitiesDefaultState: EntitiesStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const entities = (state = entitiesDefaultState, action: GetEntitiesActionType) => {
  switch (action.type) {
    case actionTypes.GET_ENTITIES_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.GET_ENTITIES_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        ...getSuccessState(),
      };
    case actionTypes.GET_ENTITIES_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

/************************* Company Clients *************************/
const companyClientsPaginatedDefaultState: CompanyClientsPaginatedStateType = {
  loading: false,
  error: '',
  hasError: false,
  pageSize: 1,
  pageNumber: 1,
  totalRecords: 0,
  hasMore: false,
  data: [],
};

const companyClientsPaginated = (
  state = companyClientsPaginatedDefaultState,
  action: CompanyClientsActionType,
): CompanyClientsPaginatedStateType => {
  switch (action.type) {
    case actionTypes.COMPANY_CLIENTS_LOADING: {
      return {
        ...state,
        ...getLoadingState(),
      };
    }
    case actionTypes.COMPANY_CLIENTS_SUCCESS:
      return {
        ...state,
        ...getSuccessState(),
        ...action.payload,
      };
    case actionTypes.COMPANY_CLIENTS_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

/************************* Media *************************/
const topViewDefaultState: CompanyTopViewState = {
  loading: false,
  error: '',
  hasError: false,
  companyMediaId: 0,
  mediaType: 0,
  url: '',
  order: 0,
};

const topView = (
  state = topViewDefaultState,
  action: PostCompanyTopViewActionType | CompanyTopViewActionType,
): CompanyTopViewState => {
  switch (action.type) {
    case actionTypes.TOP_VIEW_LOADING:
    case actionTypes.POST_TOP_VIEW_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.TOP_VIEW_SUCCESS:
    case actionTypes.POST_TOP_VIEW_SUCCESS:
      return produce(state, (draft) => {
        draft.companyMediaId = action.payload.data.companyMediaId;
        draft.mediaType = action.payload.data.mediaType;
        draft.order = action.payload.data.order;
        draft.url = action.payload.data.url;

        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.TOP_VIEW_FAILURE:
    case actionTypes.POST_TOP_VIEW_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

/************************* Company facilities *************************/
const companyFacilitiesState: CompanyFacilitiesStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const companyFacilities = (state = companyFacilitiesState, action: CompanyFacilitiesActionType) => {
  switch (action.type) {
    case actionTypes.COMPANY_FACILITIES_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.COMPANY_FACILITIES_SUCCESS:
      return produce(state, (draft) => {
        draft.list = action.payload.data;
        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.COMPANY_FACILITIES_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

/************************* Facilities form *************************/
const facilitiesFormDefaultState: CompanyFacilitiesFormStateType = {
  languageCode: DEFAULT_REQUEST_LANGUAGE,
};

const facilitiesForm = (
  state = facilitiesFormDefaultState,
  action: CompanyFacilitiesFormActionType,
): CompanyDetailsFormStateType => {
  switch (action.type) {
    case actionTypes.SET_COMPANY_FACILITIES_FORM_LANGUAGE_CODE:
      return {
        ...state,
        languageCode: action.payload.languageCode,
      };
    default:
      return state;
  }
};

/************************* Company facilities *************************/
const companyAmenitiesState: CompanyAmenitiesStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const companyAmenities = (state = companyAmenitiesState, action: CompanyAmenitiesActionType) => {
  switch (action.type) {
    case actionTypes.COMPANY_AMENITIES_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.COMPANY_AMENITIES_SUCCESS:
      return produce(state, (draft) => {
        draft.list = action.payload.data;
        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.COMPANY_AMENITIES_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

/************************* Amenities form *************************/
const amenitiesFormDefaultState: CompanyAmenitiesFormStateType = {
  languageCode: DEFAULT_REQUEST_LANGUAGE,
};

const amenitiesForm = (
  state = amenitiesFormDefaultState,
  action: CompanyAmenitiesFormActionType,
): CompanyAmenitiesFormStateType => {
  switch (action.type) {
    case actionTypes.SET_COMPANY_AMENITIES_FORM_LANGUAGE_CODE:
      return {
        ...state,
        languageCode: action.payload.languageCode,
      };
    default:
      return state;
  }
};

/************************* Alerts *************************/
const companyAlertsState: CompanyAlertsStateType = {
  loading: false,
  error: '',
  hasError: false,
  list: [],
};

const companyAlerts = (state = companyAlertsState, action: CompanyAlertsActionType) => {
  switch (action.type) {
    case actionTypes.COMPANY_ALERTS_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.COMPANY_ALERTS_SUCCESS:
      return produce(state, (draft) => {
        draft.list = action.payload.data;
        assignSuccessState(draft, { ...getSuccessState() });
      });
    case actionTypes.COMPANY_ALERTS_FAILURE:
      return { ...state, ...getFailureState(action.payload.error) };
    default:
      return state;
  }
};

const combinedReducers = combineReducers({
  companiesPaginated,
  selectedCompany,
  openingHours,
  openingHoursExceptions,
  detailsForm,
  logo,
  media,
  coverPhoto,
  companyUsersPaginated,
  companyClientsPaginated,
  companyUserInvitationsPaginated,
  entities,
  topView,
  companyFacilities,
  facilitiesForm,
  companyAmenities,
  amenitiesForm,
  companyAlerts,
});

const companiesReducer = (state: any, action: any) => {
  if (action.type === actionTypes.CLEAR_SELECTED_COMPANY) {
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};

export default companiesReducer;
