// ** React Imports
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/store';

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

/************************* React select *************************/
import 'assets/scss/react/libs/react-select/_react-select.scss';
import 'assets/scss/react/index.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

import NetworkService from './services/networkService';
import { AbilityContext } from 'utility/context/Can';
import ability from 'configs/casl/ability';

// ** i18n
import './configs/i18n';
import moment from 'moment-timezone';
import 'moment/locale/el';
import { APP_VERSION } from 'constants/app';
import SignalRProvider from 'utility/context/SignalRProvider';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG || '',
  appVersion: APP_VERSION,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE || 'development',
  enabledReleaseStages: ['production', 'uat', 'testing'],
});

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));
// Init axios
NetworkService.setupInterceptors(store);

// TODO: This should be set to utc in order to avoid bugs like adding minutes. Then we should explicitly add
// the tz when needed. This affects other places too
moment.tz.setDefault('Europe/Athens');

const ContentJSX = (
  <Provider store={store}>
    <SignalRProvider>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </SignalRProvider>
  </Provider>
);

const renderContent = () => {
  if (process.env.NODE_ENV === 'development') {
    return ContentJSX;
  }

  const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
  return <ErrorBoundary>{ContentJSX}</ErrorBoundary>;
};

ReactDOM.render(renderContent(), document.getElementById('root'));

// If you want your app to work offline and load faster, you CAN change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
