export const GET_COMPANIES_LOADING = 'GET_COMPANIES_LOADING';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';
export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';
export const GET_COMPANY_LOADING = 'GET_COMPANY_LOADING';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';
export const POST_COMPANY_DETAILS_LOADING = 'POST_COMPANY_DETAILS_LOADING';
export const POST_COMPANY_DETAILS_SUCCESS = 'POST_COMPANY_DETAILS_SUCCESS';
export const POST_COMPANY_DETAILS_FAILURE = 'POST_COMPANY_DETAILS_FAILURE';
/************************* Selected Company *************************/
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const CLEAR_SELECTED_COMPANY = 'CLEAR_SELECTED_COMPANY';
export const POST_OPENING_HOURS_LOADING = 'POST_OPENING_HOURS_LOADING';
export const POST_OPENING_HOURS_SUCCESS = 'POST_OPENING_HOURS_SUCCESS';
export const POST_OPENING_HOURS_FAILURE = 'POST_OPENING_HOURS_FAILURE';
export const GET_OPENING_HOURS_LOADING = 'GET_OPENING_HOURS_LOADING';
export const GET_OPENING_HOURS_SUCCESS = 'GET_OPENING_HOURS_SUCCESS';
export const GET_OPENING_HOURS_FAILURE = 'GET_OPENING_HOURS_FAILURE';
export const SET_COMPANY_DETAILS_FORM_LANGUAGE_CODE = 'SET_COMPANY_DETAILS_FORM_LANGUAGE_CODE';
/************************* Logo *************************/
export const CHANGE_COMPANY_LOGO_LOADING = 'CHANGE_COMPANY_LOGO_LOADING';
export const CHANGE_COMPANY_LOGO_SUCCESS = 'CHANGE_COMPANY_LOGO_SUCCESS';
export const CHANGE_COMPANY_LOGO_FAILURE = 'CHANGE_COMPANY_LOGO_FAILURE';
export const GET_COMPANY_LOGO_LOADING = 'GET_COMPANY_LOGO_LOADING';
export const GET_COMPANY_LOGO_SUCCESS = 'GET_COMPANY_LOGO_SUCCESS';
export const GET_COMPANY_LOGO_FAILURE = 'GET_COMPANY_LOGO_FAILURE';
/************************* Cover photo *************************/
export const POST_COMPANY_COVER_PHOTO_LOADING = 'POST_COMPANY_COVER_PHOTO_LOADING';
export const POST_COMPANY_COVER_PHOTO_SUCCESS = 'POST_COMPANY_COVER_PHOTO_SUCCESS';
export const POST_COMPANY_COVER_PHOTO_FAILURE = 'POST_COMPANY_COVER_PHOTO_FAILURE';
export const GET_COMPANY_COVER_PHOTO_LOADING = 'GET_COMPANY_COVER_PHOTO_LOADING';
export const GET_COMPANY_COVER_PHOTO_SUCCESS = 'GET_COMPANY_COVER_PHOTO_SUCCESS';
export const GET_COMPANY_COVER_PHOTO_FAILURE = 'GET_COMPANY_COVER_PHOTO_FAILURE';
export const DELETE_COMPANY_COVER_PHOTO_LOADING = 'DELETE_COMPANY_COVER_PHOTO_LOADING';
export const DELETE_COMPANY_COVER_PHOTO_SUCCESS = 'DELETE_COMPANY_COVER_PHOTO_SUCCESS';
export const DELETE_COMPANY_COVER_PHOTO_FAILURE = 'DELETE_COMPANY_COVER_PHOTO_FAILURE';
/************************* Media *************************/
export const POST_COMPANY_MEDIA_LOADING = 'POST_COMPANY_MEDIA_LOADING';
export const POST_COMPANY_MEDIA_SUCCESS = 'POST_COMPANY_MEDIA_SUCCESS';
export const POST_COMPANY_MEDIA_FAILURE = 'POST_COMPANY_MEDIA_FAILURE';
export const GET_COMPANY_MEDIA_LOADING = 'GET_COMPANY_MEDIA_LOADING';
export const GET_COMPANY_MEDIA_SUCCESS = 'GET_COMPANY_MEDIA_SUCCESS';
export const GET_COMPANY_MEDIA_FAILURE = 'GET_COMPANY_MEDIA_FAILURE';
export const DELETE_COMPANY_MEDIA_LOADING = 'DELETE_COMPANY_MEDIA_LOADING';
export const DELETE_COMPANY_MEDIA_SUCCESS = 'DELETE_COMPANY_MEDIA_SUCCESS';
export const DELETE_COMPANY_MEDIA_FAILURE = 'DELETE_COMPANY_MEDIA_FAILURE';
/************************* Company users *************************/
export const COMPANY_USERS_LOADING = 'COMPANY_USERS_LOADING';
export const COMPANY_USERS_SUCCESS = 'COMPANY_USERS_SUCCESS';
export const COMPANY_USERS_FAILURE = 'COMPANY_USERS_FAILURE';
/************************* Company User Invitations *************************/
export const COMPANY_USER_INVITATIONS_LOADING = 'COMPANY_USER_INVITATIONS_LOADING';
export const COMPANY_USER_INVITATIONS_SUCCESS = 'COMPANY_USER_INVITATIONS_SUCCESS';
export const COMPANY_USER_INVITATIONS_FAILURE = 'COMPANY_USER_INVITATIONS_FAILURE';
/************************* Entities *************************/
export const GET_ENTITIES_LOADING = 'GET_ENTITIES_LOADING';
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS';
export const GET_ENTITIES_FAILURE = 'GET_ENTITIES_FAILURE';
/************************* Compny Clients *************************/
export const COMPANY_CLIENTS_LOADING = 'COMPANY_CLIENTS_LOADING';
export const COMPANY_CLIENTS_SUCCESS = 'COMPANY_CLIENTS_SUCCESS';
export const COMPANY_CLIENTS_FAILURE = 'COMPANY_CLIENTS_FAILURE';
/************************* Top View *************************/
export const POST_TOP_VIEW_LOADING = 'POST_TOP_VIEW_LOADING';
export const POST_TOP_VIEW_SUCCESS = 'POST_TOP_VIEW_SUCCESS';
export const POST_TOP_VIEW_FAILURE = 'POST_TOP_VIEW_FAILURE';
export const TOP_VIEW_LOADING = 'TOP_VIEW_LOADING';
export const TOP_VIEW_SUCCESS = 'TOP_VIEW_SUCCESS';
export const TOP_VIEW_FAILURE = 'TOP_VIEW_FAILURE';
/************************* Opening hours exceptions *************************/
export const OPENING_HOURS_EXCEPTIONS_LOADING = 'OPENING_HOURS_EXCEPTIONS_LOADING';
export const OPENING_HOURS_EXCEPTIONS_SUCCESS = 'OPENING_HOURS_EXCEPTIONS_SUCCESS';
export const OPENING_HOURS_EXCEPTIONS_FAILURE = 'OPENING_HOURS_EXCEPTIONS_FAILURE';
/************************* Company facilities *************************/
export const COMPANY_FACILITIES_LOADING = 'COMPANY_FACILITIES_LOADING';
export const COMPANY_FACILITIES_SUCCESS = 'COMPANY_FACILITIES_SUCCESS';
export const COMPANY_FACILITIES_FAILURE = 'COMPANY_FACILITIES_FAILURE';
export const SET_COMPANY_FACILITIES_FORM_LANGUAGE_CODE = 'SET_COMPANY_FACILITIES_FORM_LANGUAGE_CODE';
/************************* Company amenities *************************/
export const COMPANY_AMENITIES_LOADING = 'COMPANY_AMENITIES_LOADING';
export const COMPANY_AMENITIES_SUCCESS = 'COMPANY_AMENITIES_SUCCESS';
export const COMPANY_AMENITIES_FAILURE = 'COMPANY_AMENITIES_FAILURE';
export const SET_COMPANY_AMENITIES_FORM_LANGUAGE_CODE = 'SET_COMPANY_AMENITIES_FORM_LANGUAGE_CODE';
/************************* Alerts *************************/
export const COMPANY_ALERTS_LOADING = 'COMPANY_ALERTS_LOADING';
export const COMPANY_ALERTS_SUCCESS = 'COMPANY_ALERTS_SUCCESS';
export const COMPANY_ALERTS_FAILURE = 'COMPANY_ALERTS_FAILURE';
