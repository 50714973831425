export const GET_ROLES_LOADING = 'GET_ROLES_LOADING';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';
/************************* Colours *************************/
export const COLOURS_LOADING = 'COLOURS_LOADING';
export const COLOURS_SUCCESS = 'COLOURS_SUCCESS';
export const COLOURS_FAILURE = 'COLOURS_FAILURE';
/************************* Country Phone Prefixes *************************/
export const COUNTRY_PHONE_PREFIXES_LOADING = 'COUNTRY_PHONE_PREFIXES_LOADING';
export const COUNTRY_PHONE_PREFIXES_SUCCESS = 'COUNTRY_PHONE_PREFIXES_SUCCESS';
export const COUNTRY_PHONE_PREFIXES_FAILURE = 'COUNTRY_PHONE_PREFIXES_FAILURE';
