import axios, { AxiosError, AxiosInstance } from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { appLanguageSelector } from 'redux/global/selectors';
import { BASE_API_URL, UNAUTHORISED } from '../constants/api';
import * as authActions from '../redux/global/auth/actions';
import createApiError from 'utility/api/createApiError';

function createAxiosInstance(): AxiosInstance {
  return axios.create({
    baseURL: BASE_API_URL,
    headers: {
      'Accept-Language': 'el-GR',
      'x-client': 'Suite',
      'x-platform': 'web',
    },
  });
}

function setRequestInterceptor(instance: AxiosInstance, dispatch: Dispatch<AnyAction>, getState: any) {
  instance.interceptors.request.use(
    (config) => {
      const token = getState().global.auth.token;
      const language = appLanguageSelector(getState());
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }
      config.headers['Accept-Language'] = language === 'en' ? 'en-US' : 'el-GR';
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

function setResponseInterceptor(instance: AxiosInstance, dispatch: Dispatch<AnyAction>) {
  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response && error.response.status === UNAUTHORISED) {
        dispatch(authActions.setUserNotAuthenticated());
      }
      return Promise.reject(createApiError(error as AxiosError<{ errorCode?: number }>));
    },
  );
}

function setInterceptors(instance: AxiosInstance, dispatch: Dispatch<AnyAction>, getState: any) {
  setRequestInterceptor(instance, dispatch, getState);
  setResponseInterceptor(instance, dispatch);
}

export default (function () {
  let instance: AxiosInstance;
  let interceptorsSet: boolean;

  return {
    setupInterceptors: function (store: any) {
      const { dispatch, getState } = store;

      if (!interceptorsSet) {
        setInterceptors(this.getInstance(), dispatch, getState);
        interceptorsSet = true;
      }
    },
    getInstance: function (): AxiosInstance {
      if (!instance) {
        instance = createAxiosInstance();
      }
      return instance;
    },
  };
})();
