// TODO: They are fucking retired. They moved to redux/toolkit in bootstrap v5. Just to make it work. Refactor this
// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig';

// ** Handles Layout Content Width (full / boxed)
export const handleContentWidth = (value) => (dispatch) => dispatch({ type: 'HANDLE_CONTENT_WIDTH', value });

// ** Handles Menu Collapsed State (Bool)
export const handleMenuCollapsed = (value) => (dispatch) => dispatch({ type: 'HANDLE_MENU_COLLAPSED', value });

// ** Handles Menu Hidden State (Bool)
export const handleMenuHidden = (value) => (dispatch) => dispatch({ type: 'HANDLE_MENU_HIDDEN', value });

export const handleLayout = (value) => (dispatch) => dispatch({ type: 'HANDLE_LAYOUT', value });

// ** Handles RTL (Bool)
export const handleRTL = (value) => (dispatch) => dispatch({ type: 'HANDLE_RTL', value });

// ** Handle Layout Skin
export const handleSkin = (value) => (dispatch) => dispatch({ type: 'HANDLE_SKIN', value });

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed');
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

// ** Returns Initial Layout Skin
const initialLayoutSkin = () => {
  const item = window.localStorage.getItem('skin');
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.skin;
};

// ** Initial State
const initialState = {
  skin: initialLayoutSkin(),
  navbarColor: 'white',
  layout: themeConfig.layout.type,
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  routerTransition: themeConfig.layout.routerTransition,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_CONTENT_WIDTH':
      return { ...state, contentWidth: action.value };
    case 'HANDLE_MENU_COLLAPSED':
      window.localStorage.setItem('menuCollapsed', action.value);
      return { ...state, menuCollapsed: action.value };
    case 'HANDLE_MENU_HIDDEN':
      return { ...state, menuHidden: action.value };
    case 'HANDLE_RTL':
      return { ...state, isRTL: action.value };
    case 'HANDLE_SKIN':
      return { ...state, skin: action.value };
    case 'HANDLE_LAYOUT':
      return { ...state, layout: action.value };
    default:
      return state;
  }
};

export default layoutReducer;
