import { DEFAULT_DISPLAY_LANGUAGE } from 'constants/app';
import * as actionTypes from './actionTypes';
import {
  AppStateType,
  HideSpinnerActionType,
  InitializeAppActionType,
  NavbarActionType,
  NavbarStateType,
  SetLanguageActionType,
  ShowSpinnerActionType,
} from './types';

const appDefaultState: AppStateType = {
  initializing: true,
  language: DEFAULT_DISPLAY_LANGUAGE,
};

export const app = (state = appDefaultState, action: InitializeAppActionType | SetLanguageActionType): AppStateType => {
  switch (action.type) {
    case actionTypes.INITIALIZING_APP:
      return {
        ...state,
        initializing: action.payload.initializing,
      };
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      };
    default: {
      return state;
    }
  }
};

/************************* Navbar *************************/
export const navbarDefaultState: NavbarStateType = {
  title: undefined,
};

export const navbar = (state = navbarDefaultState, action: NavbarActionType): NavbarStateType => {
  switch (action.type) {
    case actionTypes.SET_NAVBAR_TITLE: {
      return {
        ...state,
        title: action.payload.title,
      };
    }
    case actionTypes.CLEAR_NAVBAR_TITLE:
      return {
        ...state,
        title: undefined,
      };
    default:
      return state;
  }
};

export const spinner = (state = false, action: ShowSpinnerActionType | HideSpinnerActionType): boolean => {
  switch (action.type) {
    case actionTypes.SHOW_SPINNER:
      return true;
    case actionTypes.HIDE_SPINNER:
      return false;
    default:
      return state;
  }
};
