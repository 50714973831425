/************************* Actions *************************/
export const ACTION_CREATE = 'create';
export const ACTION_READ = 'read';
export const ACTION_UPDATE = 'update';
export const ACTION_DELETE = 'delete';
export const ACTION_MANAGE = 'manage';

/************************* Subjects *************************/
export const SUBJECT_ALL = 'All';
export const SUBJECT_HOME = 'Home';
export const SUBJECT_APPS = 'Apps';
export const SUBJECT_COMPANY = 'Company';
export const SUBJECT_OTHER = 'Other';
export const SUBJECT_ACTIVITY = 'Activity';
export const SUBJECT_LANGUAGE = 'NewLanguage';
export const SUBJECT_COMPANY_INFO = 'CompanyInfo';
export const SUBJECT_COMPANY_HOURS = 'CompanyHours';
export const SUBJECT_COMPANY_EXCEPTIONS = 'CompanyExceptions';
export const SUBJECT_COMPANY_AMENITIES = 'CompanyAmenities';
export const SUBJECT_COMPANY_FACILITIES = 'CompanyFacilities';
export const SUBJECT_COMPANY_MEDIA = 'CompanyMedia';
export const SUBJECT_COMPANY_ENTITIES = 'CompanyEntities';
export const SUBJECT_INVITE_BUSINESS_OWNERS = 'InviteBusinessOwners';
export const SUBJECT_INVITE_USERS = 'InviteUsers';
export const SUBJECT_BOOKINGS = 'Bookings';
export const SUBJECT_BOOKINGS_SYSTEM = 'BookingsSystem';
export const SUBJECT_FINANCIAL_INFO = 'Financial';
export const SUBJECT_INVOICES = 'Invoices';
export const SUBJECT_CONTACTS = 'Contacts';
export const SUBJECT_SUPER_USER_MANAGEMENT = 'SuperUserManagement';
