import { EMPTY_ARRAY } from 'constants/app';
import produce from 'immer';
import { assignSuccessState, getFailureState, getLoadingState, getSuccessState } from 'utility/redux';
import * as actionTypes from './actionTypes';
import { AddLanguageActionType, EditLanguageActionType, GetLanguagesActionTypes, GetLanguagesStateType } from './types';

const languagesDefaultState: GetLanguagesStateType = {
  loading: false,
  hasError: false,
  error: '',
  list: EMPTY_ARRAY,
};

const languages = (
  state = languagesDefaultState,
  action: GetLanguagesActionTypes | AddLanguageActionType | EditLanguageActionType,
) => {
  switch (action.type) {
    case actionTypes.GET_LANGUAGES_LOADING:
    case actionTypes.ADD_LANGUAGE_LOADING:
    case actionTypes.EDIT_LANGUAGE_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.GET_LANGUAGES_SUCCESS:
    case actionTypes.EDIT_LANGUAGE_SUCCESS:
      return produce(state, (draft) => {
        draft.list = action.payload.languages;

        assignSuccessState(draft, getSuccessState());
      });
    case actionTypes.ADD_LANGUAGE_SUCCESS:
      return produce(state, (draft) => {
        draft.list.push(action.payload.language);

        assignSuccessState(draft, getSuccessState());
      });
    case actionTypes.GET_LANGUAGES_FAILURE:
    case actionTypes.EDIT_LANGUAGE_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};

export default languages;
