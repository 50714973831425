import { ACTION_READ, SUBJECT_HOME } from 'constants/casl';
import { PATHS } from 'constants/paths';
import { Home } from 'react-feather';
import { MenuItemType } from 'redux/global/menu/types';

const navigation: MenuItemType[] = [
  {
    id: 'homeCompanies',
    title: 'home.companySelect',
    icon: <Home size={20} />,
    navLink: PATHS.home.companies,
    action: ACTION_READ,
    resource: SUBJECT_HOME,
  },
];

export default navigation;
