import { PATHS } from 'constants/paths';
import produce from 'immer';
import navigation from 'navigation/vertical';
import {
  APPS_BOOKINGS_HISTORY,
  APPS_BUSINESS_DETAILS,
  APPS_CALENDAR,
  APPS_COMPANY_USERS,
  APPS_CONTACTS,
  APPS_DASHBOARD,
  APPS_FIELDS,
  APPS_HEADER_ID,
  APPS_INVOICES,
} from 'navigation/vertical/apps';
import { combineReducers } from 'redux';
import { SET_SELECTED_COMPANY } from 'redux/companies/actionTypes';
import * as actionTypes from './actionTypes';
import { AppsActionsType, MenuItemType } from './types';

const defaultState: MenuItemType[] = navigation;

const items = (state = defaultState, action: AppsActionsType) => {
  switch (action.type) {
    case actionTypes.SET_APPS_HEADER: {
      return produce(state, (draft) => {
        const appsHeaderIndex = state.findIndex((item) => item.id === APPS_HEADER_ID);

        if (appsHeaderIndex !== -1) {
          draft[appsHeaderIndex].header = action.payload.header;
        }
      });
    }
    case actionTypes.CLEAR_APPS_HEADER: {
      return produce(state, (draft) => {
        const appsHeaderIndex = state.findIndex((item) => item.id === APPS_HEADER_ID);

        if (appsHeaderIndex !== -1) {
          draft[appsHeaderIndex].header = defaultState[appsHeaderIndex].header;
        }
      });
    }
    case actionTypes.SET_ENTITIES_BADGE: {
      return produce(state, (draft) => {
        const entitiesItemIndex = state.findIndex((item) => item.id === APPS_FIELDS);

        if (entitiesItemIndex !== -1) {
          draft[entitiesItemIndex].badge = 'success';
          draft[entitiesItemIndex].badgeText = action.payload.entitiesNum.toString();
        }
      });
    }
    case SET_SELECTED_COMPANY: {
      return produce(state, (draft) => {
        const company = action.payload.company;

        const appsHeaderIndex = state.findIndex((item) => item.id === APPS_HEADER_ID);
        const appsDashboardIndex = state.findIndex((item) => item.id === APPS_DASHBOARD);
        const appsCalendarIndex = state.findIndex((item) => item.id === APPS_CALENDAR);
        const appsContactsIndex = state.findIndex((item) => item.id === APPS_CONTACTS);
        const appsFieldsIndex = state.findIndex((item) => item.id === APPS_FIELDS);
        const appsBusinessDetailsIndex = state.findIndex((item) => item.id === APPS_BUSINESS_DETAILS);
        const appsCompanyUsersIndex = state.findIndex((item) => item.id === APPS_COMPANY_USERS);
        const appsInvoicesIndex = state.findIndex((item) => item.id === APPS_INVOICES);
        const appsBookingsHistoryIndex = state.findIndex((item) => item.id === APPS_BOOKINGS_HISTORY);

        const companyId = company.companyId;
        draft[appsHeaderIndex].header = company.title;
        // draft[appsDashboardIndex].navLink = `${PATHS.apps.dashboard}/${companyId}`;
        draft[appsCalendarIndex].navLink = `${PATHS.apps.bookings}/${companyId}`;
        draft[appsContactsIndex].navLink = `${PATHS.apps.companyClients}/${companyId}`;
        draft[appsFieldsIndex].navLink = `${PATHS.apps.entities}/${companyId}`;
        draft[appsBusinessDetailsIndex].navLink = `${PATHS.apps.company}/${companyId}`;
        draft[appsCompanyUsersIndex].navLink = `${PATHS.apps.companyUsers}/${companyId}`;
        draft[appsInvoicesIndex].navLink = `${PATHS.apps.invoices}/${companyId}`;
        draft[appsBookingsHistoryIndex].navLink = `${PATHS.apps.bookingsHistory}/${companyId}`;
      });
    }
    case actionTypes.HIDE_HOME_MENU_ITEMS:
      return state.filter((item) => !item.id?.startsWith('home'));
    default:
      return state;
  }
};

export default combineReducers({
  items,
});
