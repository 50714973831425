import { SortOrderEnum } from 'types/enums';
import { PaginationRequestType } from 'types/pagination';
import { getBusinessHoursOptions } from 'utility/app';
import { version } from '../../package.json';

export const APP_VERSION = version;
export const ALL_ID = -1;
export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
export const LOCALE_EL = 'el';
export const LOCALE_EN = 'en';
export const DEFAULT_REQUEST_LANGUAGE = LOCALE_EN;
export const DEFAULT_DISPLAY_LANGUAGE = LOCALE_EN;
export const COUNTRY_LOCALES = Object.freeze({
  el: 'el_GR',
  en: 'en_US',
});

/************************* Pagination *************************/
export const PAGINATION_DISPLAY_PAGES = 5;
export const PAGINATION_DEFAULT_DATA: PaginationRequestType = Object.freeze({
  pageSize: PAGINATION_DISPLAY_PAGES,
  pageNum: 1,
  sortOrder: SortOrderEnum.asc,
});

/************************* Forms *************************/
export const PASSWORD_MIN_LENGTH = 8;

/************************* React select *************************/
export const SELECT_ALL_VALUE = '0';

/************************* Roles *************************/
export const ROLE_HF_USER = 'HFUser';
export const ROLE_SUPERUSER = 'Superuser';
export const ROLE_HF_SUPPORT = 'HFSupport';
export const ROLE_BUSINESS_ADMIN = 'BusinessAdmin';
export const ROLE_BUSINESS_USER = 'BusinessUser';

/************************* Business hours *************************/
export const businessHours = getBusinessHoursOptions();

/************************* Date *************************/
export const DATE_TIME_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_WITHOUT_TIME = 'YYYY-MM-DD';

export const ALL_DAY_HOURS_IN_MIN = 24 * 60;
