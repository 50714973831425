import { CountriesStateType, GetCountriesActionType } from './types';
import * as actionTypes from './actionTypes';
import { getFailureState, getLoadingState, getSuccessState } from 'utility/redux';
import { EMPTY_ARRAY } from 'constants/app';

const countriesDefaultState: CountriesStateType = {
  list: EMPTY_ARRAY,
  loading: false,
  hasError: false,
  error: '',
};

export const countries = (state = countriesDefaultState, action: GetCountriesActionType): CountriesStateType => {
  switch (action.type) {
    case actionTypes.GET_COUNTRIES_LOADING:
      return {
        ...state,
        ...getLoadingState(),
      };
    case actionTypes.GET_COUNTRIES_SUCCESS:
      return {
        list: action.payload.countries,
        ...getSuccessState(),
      };
    case actionTypes.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        ...getFailureState(action.payload.error),
      };
    default:
      return state;
  }
};
